import { isNullOrUndef, jsonToUri } from 'utils';
import { envConfig } from 'config';
import { WidgetOverrideParams } from 'consts';

// eslint-disable-next-line max-len
export const getConfigUrl = (state, targetId, instanceId, apiEndpoint, urlContext, topmostPageUrl, csAlias, compliance) => {
  const { protocol, majorVersion } = envConfig;
  const { hostname } = state;
  const configApiUrl = `${protocol}${hostname}${apiEndpoint}`;
  const containerEl = document.getElementById(instanceId);
  const configParams = {
    'target': targetId,
    'instance': instanceId,
    'context': urlContext,
    'mv': majorVersion,
    '_': Date.now()
  };

  //add url-based overrides if they exist
  if (!isNullOrUndef(topmostPageUrl.match(new RegExp(`[?&]${WidgetOverrideParams.Draft}(=([^&]*))?`)))) {
    configParams.theme = 'draft';
  }
  if (!isNullOrUndef(topmostPageUrl.match(new RegExp(`[?&]${WidgetOverrideParams.Oip}(=([^&]*))?`)))) {
    configParams.oip = 1;
  }
  if (!isNullOrUndef(containerEl)) {
    configParams.cw = containerEl.offsetWidth;
  }
  //if compliance already accepted and we have an existing cs alias
  //pass to target endpoint for potential cscookie->pubcookie upgrade
  if (!isNullOrUndef(compliance) && !isNullOrUndef(csAlias)) {
    const { enabled, accepted, version } = compliance;
    if (enabled && accepted) {
      configParams.csAlias = csAlias;
      configParams.ppv = version;
    }
  }

  const configParamsString = jsonToUri(configParams);

  return `${configApiUrl}?${configParamsString}`;
};

export default getConfigUrl;
